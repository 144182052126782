import Swiper from 'swiper';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import {TweenMax, TimelineMax, Power3, Power4 } from 'gsap';
import ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
//import 'bootstrap';

//require('popper.js');
import 'youtube-background';

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

(function($) {

    var winScrollTop = 0;
    var _animationScrollTop;
    var _controllerScrollMagic;
    var _dropdownInAnimation;

    /*----------------------------------------------------------------------------- 
    ------- ready
    ----------------------------------------------------------------------------- */

    $(document).ready(function() {

        _animationScrollTop = TweenMax.to($('#scroll-to-top'), 0.5, { opacity: 1, ease: Power4.easeInOut, paused: true });
        winScrollTop = $(this).scrollTop();

        if ($('#video-home-holder').length != 0) {

            $('[data-vbg]').youtube_background({
                'play-button': false,
                'autoplay': true,
                'muted': true,
                'loop': true,
                'always-play': true,
                'no-cookie': true,
                'lazyloading': true,
            });
        }

        $('#scroll-to-top').bind('click', function(event) {
            TweenMax.to(window, 1.5, { scrollTo: { y: 0 }, ease: Power4.easeInOut });
            event.preventDefault();
            return false;
        });

        if ($('.title-collapse').length != 0) {
            $('.title-collapse').unbind('click').bind('click', function() {
                $(this).toggleClass("opened").next().slideToggle(400);

                if ($(this).hasClass("opened")) {
                    TweenMax.to(window, 1, { scrollTo: { y: $(this).offset().top - 150, delay: 4 }, ease: Power4.easeInOut });
                }
                return false;
            });
        }

        if ($('#header').length != 0) {
            $('#header').removeClass('minimize-header');
            $('#header').addClass('expand-header');
        }

        if ($('#filters').length != 0) {
            $('.filters-btn').unbind('click').bind('click', function() {
                $(this).toggleClass("opened");

                var $fm = $(this).parent().find('.filters-menu');
                var $fm_bg = $fm.find('.filters-menu-bg');
                var $fc = $fm.find('.filters-content');


                if ($(this).hasClass("opened")) {


                    $fm.show();
                    TweenMax.to($fm_bg, 0.5, {
                        height: $fc.outerHeight() + "px",
                        ease: Power4.easeInOut,
                        onComplete: function() {

                            TweenMax.staggerTo($fm.find('a'), 0.5, { opacity: 1, ease: Power4.easeInOut }, 0.1);
                        }
                    });

                } else {

                    TweenMax.staggerTo($fm.find('a').get().reverse(), 0.2, {
                        opacity: 0,
                        ease: Power4.easeInOut,
                        onComplete: function() {
                            TweenMax.to($fm_bg, 0.5, {
                                height: 0,
                                ease: Power4.easeInOut,
                                onComplete: function() {
                                    $fm.hide();
                                }
                            });
                        }
                    }, 0.05);


                }
                return false;
            });
        }

        if ($('#typed').length != 0) {
            $("#typed").typed({
                stringsElement: $('#typed-strings'),
                typeSpeed: 100,
                backDelay: 1500,
                loop: true,
                contentType: 'html',
                loopCount: true,
                cursorChar: "_"
            });
        }

        if ($('.mouse').length != 0) {
            $('.mouse').on('touchstart click', function(event) {
                event.preventDefault();
                $("body, html").animate({ scrollTop: ($(window).height()) }, 1000, "easeInOutQuad");
                return false;
            });
        }



        $('#menu-btn').bind('click', function(event) {


            // overlay start

            $("#overlay").height(0).css('top', '-10%').show();
            TweenMax.to($("#overlay"), 0.4, { skewY: "6deg" });
            TweenMax.to($("#overlay"), 0.3, { delay: 0.3, skewY: "-6deg" });
            TweenMax.to($("#overlay"), 0.3, { delay: 0.7, skewY: "0deg" });
            TweenMax.to($("#overlay"), 1, {
                scaleX: 1.2,
                scaleX: 1.2,
                height: '110%',
                ease: Power4.easeIn,
                onComplete: function() {

                    // overlay inner start

                    $("#overlay-inner").width(0).css('left', '-10%').show();
                    TweenMax.to($("#overlay-inner"), 0, { skewX: "3deg" });
                    TweenMax.to($("#overlay-inner"), 0.4, { delay: 0.4, skewX: "0deg" });
                    TweenMax.to($("#overlay-inner"), 0.8, {
                        scaleX: 1,
                        scaleX: 1,
                        width: '60%',
                        ease: Power4.easeOut,
                        onComplete: function() {

                            $('#menu-mondo > .menu-item > a > span').css({ transform: 'translateY(50px)' })
                            $('.dropdown-menu').find('a span').css({ transform: 'translateY(50px)' })
                            $('.menu-item-has-children').removeClass('open');

                            // menu start

                            $('#world-menu').fadeIn();
                            TweenMax.staggerTo($('#menu-mondo > .menu-item > a > span'), 0.8, { y: 0, delay: 0, ease: Power4.easeInOut, overwrite: false }, 0.1);

                            $(".close-ajax").click(function(e) {
                                TweenMax.staggerTo($('#menu-mondo > .menu-item > a > span').get().reverse(), 0.5, {
                                    y: "50px",
                                    delay: 0,
                                    ease: Power4.easeInOut,
                                    onComplete: function() {
                                        $('#world-menu').fadeOut();

                                        // overlay inner end

                                        TweenMax.to($("#overlay-inner"), 0.4, { skewX: "3deg" });
                                        TweenMax.to($("#overlay-inner"), 0.8, {
                                            scaleX: 1,
                                            scaleX: 1,
                                            width: 0,
                                            ease: Power4.easeIn,
                                            onComplete: function() {
                                                $("#overlay-inner").css('left', '0').hide();

                                                // overlay end

                                                TweenMax.to($("#overlay"), 0.4, { skewY: "6deg" });
                                                TweenMax.to($("#overlay"), 0.3, { delay: 0.3, skewY: "-6deg" });
                                                TweenMax.to($("#overlay"), 0.3, { delay: 0.7, skewY: "0deg" });
                                                TweenMax.to($("#overlay"), 1, {
                                                    scaleX: 1,
                                                    scaleX: 1,
                                                    height: 0,
                                                    ease: Power4.easeInOut,
                                                    onComplete: function() {
                                                        $("#overlay").css('top', '0').hide();
                                                    }
                                                });

                                            }
                                        })

                                    },
                                    overwrite: false
                                }, 0.1);

                                return false;
                            });

                        }
                    });

                }
            });
            return false;
        });

        $('.dropdown-toggle').bind('click', function(event) {
            var $dropdown_menu = $(this).parent().find('.dropdown-menu');
            if (!_dropdownInAnimation) {
                _dropdownInAnimation = true;
                if ($(this).parent().hasClass('open')) {
                    TweenMax.staggerTo($dropdown_menu.find('a span').get().reverse(), 0.5, {
                        y: "50px",
                        delay: 0,
                        ease: Power4.easeInOut,
                        onComplete: function() {
                            $dropdown_menu.hide();
                            _dropdownInAnimation = false;
                        },
                        overwrite: true
                    }, 0.1);
                    $(this).parent().removeClass('open');
                } else {
                    $dropdown_menu.show();
                    TweenMax.staggerTo($dropdown_menu.find('a span'), 0.8, {
                        y: 0,
                        delay: 0,
                        ease: Power4.easeInOut,
                        onComplete: function() {
                            _dropdownInAnimation = false;
                        },
                        overwrite: true
                    }, 0.1);
                    $(this).parent().addClass('open');
                }
            }
            return false;
        });

        $(document).trigger('ajaxComplete');

        $(window).on('shown.bs.modal', function() {
            setupFormAcf();
        });

        if ($('.bttrlazyloading').length != 0) {
            $('.bttrlazyloading').bttrlazyloading({
                animation: 'fadeInUp',
                event: 'scroll',
                delay: 200,
                threshold: 300,
                backgroundcolor: '#F9F8F6',
                xs: {
                    width: 800,
                    height: 800
                },
                sm: {
                    width: 800,
                    height: 800
                },
                md: {
                    width: 800,
                    height: 800
                },
                lg: {
                    width: 800,
                    height: 800
                }
            });
        }

        if ($('#slider').length != 0) {
            var slider = new Swiper('#slider .swiper-container', {
                pagination: {
                    el: '#slider .swiper-pagination',
                    clickable: true,
                    renderBullet: function(index, className) {
                        return '<span class="' + className + '">' + (index + 1) + '</span>';
                    },
                },
                autoplay: {
                    delay: 6000,
                    disableOnInteraction: false
                },
                paginationClickable: true,
                grabCursor: false,
                preloadImages: false,
                lazyLoading: true,
                centeredSlides: true,
                calculateHeight: true,
                speed: 1000,
                effect: 'slide'
            });
        }

        $.fn.randomize = function(childElem) {
            return this.each(function() {
                var $this = $(this);
                var elems = $this.children(childElem);

                elems.sort(function() { return (Math.round(Math.random()) - 0.5); });

                $this.remove(childElem);

                for (var i = 0; i < elems.length; i++)
                    $this.append(elems[i]);

            });
        }

        if ($('.portfolio-gallery').length != 0) {


            $('.portfolio-gallery').lightGallery({
                thumbnail: false,
                selector: '.portfolio-gallery-item',
                animateThumb: false,
                showThumbByDefault: false,
                autoplay: true,
                fullScreen: false,
                cssEasing: 'cubic-bezier(0.860, 0.000, 0.070, 1.000)'
            });


        }

        if ($('.portfolio-carousel').length != 0) {
            var portfolio = new Swiper('.portfolio-carousel .swiper-container', {
                navigation: {
                    nextEl: '.portfolio-carousel-next',
                    prevEl: '.portfolio-carousel-prev',
                },
                coverflowEffect: {
                    rotate: 60,
                    slideShadows: false
                },
                paginationClickable: false,
                grabCursor: false,
                preloadImages: false,
                lazyLoading: true,
                centeredSlides: true,
                calculateHeight: true,
                speed: 600,
                effect: 'coverflow'
            });
        }


        

        animateVideoOpacity();
        animateProcessSVG_1();
        animateProcessSVG_2();
        animateProcessSVG_3();

        cursorAnimation();

        startIubendaBadge();

    });



    /*----------------------------------------------------------------------------- 
    ------- load
    ----------------------------------------------------------------------------- */

    $(window).bind("load", function() {

        TweenMax.to($('#preloadOverlayInner'), 0.5, {
            x: 200,
            opacity: 0,
            delay: 0.35,
            ease: Power3.easeIn,
            onComplete: function() {
                startTransition();
                $("body").css('overflow', 'auto');

                setTimeout(function() {
                    if ($('#header').length != 0) {
                        $('#header').removeClass('expand-header');
                        $('#header').addClass('minimize-header');
                    }
                }, 1500);

            }
        });

        $(".text-appear").blast({ delimiter: "word" }).css('opacity', 0);

        $('.item-appear').appear(function(e) {

            if ($(this).hasClass('item-appear-delay')) {
                TweenMax.to($(this), 0.35, { y: 0, opacity: 1, delay: 0.2, ease: Power3.easeIn });
            } else {
                TweenMax.to($(this), 0.35, { y: 0, opacity: 1, delay: 0, ease: Power3.easeIn });
            }

            $(this).find(".text-appear").each(function() {
                TweenMax.staggerTo($(this).find(".blast"), 0.1, { opacity: 1, delay: 0 }, 0.05);
            });
        });

        $(window).trigger('scroll');
        $(window).trigger('resize');
    });

    /*----------------------------------------------------------------------------- 
    ------- resize
    ----------------------------------------------------------------------------- */

    $(window).resize(function() {
        var height = $(window).height();
        var width = $(window).width();

        if ($('.row-child-same-height').length != 0) {
            $('.row-child-same-height').each(function() {
                $(this).find('.col-sh').css('height', 'auto');
                $(this).find('.col-sh').setAllToMaxHeight();
                TweenMax.to($(this).find('.col-sh'), 1, { opacity: 1, ease: Power4.easeInOut });
            });
        }

        if (width < 993) {
            if (_controllerScrollMagic !== null && _controllerScrollMagic !== undefined) {
                _controllerScrollMagic = _controllerScrollMagic.destroy(true);
            }
        } else {
            if (_controllerScrollMagic === null || _controllerScrollMagic === undefined) {
                initScrollMagic();
            }
        }

        parallax();

    });

    /*----------------------------------------------------------------------------- 
    ------- ajax
    ----------------------------------------------------------------------------- */

    $(document).ajaxComplete(function() {
        setupFormAcf();
    });


    /*----------------------------------------------------------------------------- 
    ------- scroll
    ----------------------------------------------------------------------------- */

    $(window).scroll(function(e) {
        winScrollTop = $(this).scrollTop();
        parallax();

        if (_animationScrollTop) {
            if ($(window).scrollTop() > $(window).height()) {
                _animationScrollTop.play();
            } else {
                _animationScrollTop.reverse();
            }
        }

    });

    /*----------------------------------------------------------------------------- 
    ------- prototype
    ----------------------------------------------------------------------------- */

    $.fn.setAllToMaxHeight = function() {
        return this.outerHeight(Math.max.apply(this, $.map(this.children(), function(e) {
            return $(e).outerHeight();
        })));
    };

    $.fn.is_on_screen = function() {
        var win = $(window);
        var viewport = {
            top: win.scrollTop(),
            left: win.scrollLeft()
        };
        viewport.bottom = viewport.top + win.height();

        var bounds = this.offset();
        bounds.bottom = bounds.top + this.outerHeight();

        return (!(viewport.bottom < bounds.top || viewport.top > bounds.bottom));
    };

    /*----------------------------------------------------------------------------- 
    ------- functions
    ----------------------------------------------------------------------------- */

    function startTransition() {
        $('#preloadOverlay').addClass('show');

    }

    function initScrollMagic() {
        if ($('.panel-minilab').length != 0) {
            $('.panel-minilab').each(function() {
                _controllerScrollMagic = new ScrollMagic.Controller();
                const current = this;
                var tween = new TimelineMax()
                    .add([
                        TweenMax.fromTo($(this).find(".img"), 1, { y: -150 }, { y: 150, ease: Linear.easeNone }),
                        TweenMax.fromTo($(this).find(".dida"), 1, { y: 50 }, { y: -50, ease: Linear.easeNone })
                    ]);
                var scene = new ScrollMagic.Scene({ triggerElement: current, duration: $(window).height() })
                    .setTween(tween)
                    .addTo(_controllerScrollMagic);
            });
        }
    }

    function parallax() {

        var scrolled = $(window).scrollTop();
        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            $('.parallax-container').each(function() {
                if ($(this).is_on_screen()) {
                    var firstTop = $(this).offset().top;
                    var $parallax = $(this).find(".parallax");
                    $parallax.height($parallax.parent().outerHeight() + 0);
                    var moveTop = (firstTop - winScrollTop) * 0.5 //speed;
                    $parallax.css("transform", "translateY(" + -moveTop + "px)");
                }
            });
        }
    }

    function setupFormAcf() {
        if ($('.form-acf').length != 0) {
            //acf.do_action('append', $('.form-acf'));
            $(document).trigger('acf/setup_fields', $('.form-acf'));
        }
    }

    function startIubendaBadge() {

        var loadIubendaBadge = function() {
            var s = document.createElement("script"),
                tag = document.getElementsByTagName("script")[0];
            s.src = "https://cdn.iubenda.com/iubenda.js";
            tag.parentNode.insertBefore(s, tag);
        };

        loadIubendaBadge();
        
    };

    function cursorAnimation() {
        var cursor = $(".cursor"),
            follower = $(".cursor-follower");

        var posX = 0,
            posY = 0;

        var mouseX = 0,
            mouseY = 0;

        TweenMax.to({}, 0.016, {
            repeat: -1,
            onRepeat: function() {
                posX += (mouseX - posX) / 9;
                posY += (mouseY - posY) / 9;

                TweenMax.set(follower, {
                    css: {
                        left: posX - 15,
                        top: posY - 15
                    }
                });
            }
        });

        $(document).on("mousemove", function(e) {
            mouseX = e.pageX;
            mouseY = e.pageY;
        });

        $("a").on("mouseenter", function() {
            follower.addClass("active");
        });

        $("a").on("mouseleave", function() {
            follower.removeClass("active");
        });

        if (Modernizr.touch) {
            cursor.hide();
            follower.hide();
        }
    }

    function animateProcessSVG_1() {

        if ($('path#svg_1').length != 0) {
            var $svg_1 = $("path#svg_1");
            pathPrepare($svg_1);
            var controller_1 = new ScrollMagic.Controller();

            var tween = new TimelineMax()
                .add(TweenMax.to($svg_1, 0.9, { strokeDashoffset: 0, ease: Linear.easeNone }))
                .add(TweenMax.to($svg_1, 0.9, { fill: "#FFFFFF", ease: Linear.easeNone }), 0)
                .add(TweenMax.to($svg_1, 0.9, { stroke: "#A5CE39", ease: Linear.easeNone }), 0);

            var scene = new ScrollMagic.Scene({ triggerElement: "#trigger_animation_1", duration: $(window).height() * 0.5, tweenChanges: true })
                .setTween(tween)
                .addTo(controller_1);
        }

    }

    function animateProcessSVG_2() {

        if ($('#process-svg').length != 0) {

            var $process_SVG = $("#process-svg");

            var controller_2 = new ScrollMagic.Controller();

            var tween_01 = TweenMax.staggerFrom($process_SVG.find('.process-tem'), 0.3, { opacity: 0, delay: 0, ease: Back.easeOut, overwrite: false }, 0.2);
            var tween_02 = TweenMax.staggerFrom($process_SVG.find('.txt-svg'), 0.4, { opacity: 0, scale: 0.5, transformOrigin: "center center", delay: 0, ease: Back.easeOut, overwrite: false }, 0.2);

            var tween_process = new TimelineMax().insert(tween_01).insert(tween_02);

            var scene_process = new ScrollMagic.Scene({ triggerElement: "#trigger_animation_2", duration: $("#process-svg").height() * 0.5, tweenChanges: true })
                .setTween(tween_process)
                .addTo(controller_2);
        }
    }

    function animateProcessSVG_3() {

        if ($('#pozione-gd-svg').length != 0 || $('#pozione-sm-svg').length != 0 || $('#pozione-wd-svg').length != 0) {
            var $pozione_SVG_gd = $("#pozione-gd-svg");
            var $pozione_SVG_sm = $("#pozione-sm-svg");
            var $pozione_SVG_wd = $("#pozione-wd-svg");

            var controller_3 = new ScrollMagic.Controller();

            var tween_1 = TweenMax.staggerFrom($pozione_SVG_gd.find('.pozione-tem'), 0.3, { opacity: 0, scale: 0.5, transformOrigin: "center center", delay: 0, ease: Back.easeOut, overwrite: false }, 0.2);
            var tween_2 = TweenMax.staggerFrom($pozione_SVG_sm.find('.pozione-tem'), 0.3, { opacity: 0, scale: 0.5, transformOrigin: "center center", delay: 0, ease: Back.easeOut, overwrite: false }, 0.2);
            var tween_3 = TweenMax.staggerFrom($pozione_SVG_wd.find('.pozione-tem'), 0.3, { opacity: 0, scale: 0.5, transformOrigin: "center center", delay: 0, ease: Back.easeOut, overwrite: false }, 0.2);

            var tween_process_1 = new TimelineMax().insert(tween_1);
            var tween_process_2 = new TimelineMax().insert(tween_2);
            var tween_process_3 = new TimelineMax().insert(tween_3);

            if ($('#pozione-gd-svg').length != 0) {
                var scene_1 = new ScrollMagic.Scene({ triggerElement: "#pozione-gd-svg", duration: $pozione_SVG_gd.height(), tweenChanges: true })
                    .setTween(tween_process_1)
                    .addTo(controller_3);
            }

            if ($('#pozione-sm-svg').length != 0) {
                var scene_2 = new ScrollMagic.Scene({ triggerElement: "#pozione-sm-svg", duration: $pozione_SVG_sm.height(), tweenChanges: true })
                    .setTween(tween_process_2)
                    .addTo(controller_3);
            }

            if ($('#pozione-wd-svg').length != 0) {
                var scene_3 = new ScrollMagic.Scene({ triggerElement: "#pozione-wd-svg", duration: $pozione_SVG_wd.height(), tweenChanges: true })
                    .setTween(tween_process_3)
                    .addTo(controller_3);
            }
        }
    }

    function animateVideoOpacity() {

        if ($('#video-home-holder').length != 0) {


            var $video_holder = $('#video-home-holder');

            var controller_video = new ScrollMagic.Controller();

            var tween_video = TweenMax.to($video_holder.find('.video-disabled'), 1, { opacity: 0.7, ease: Power4.easeInOut, overwrite: false });

            var tween_video_timeline = new TimelineMax().insert(tween_video);

            var scene_process = new ScrollMagic.Scene({ triggerElement: "#home-intro", duration: $(window).height() * 0.1, tweenChanges: true })
                .setTween(tween_video_timeline)
                .addTo(controller_video);
        }
    }

    function pathPrepare($el) {
        var lineLength = $el[0].getTotalLength();
        $el.css("stroke-dasharray", lineLength);
        $el.css("stroke-dashoffset", lineLength);
    }

})(jQuery);